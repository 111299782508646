import React, { Fragment, useCallback } from "react";
import styled from "styled-components";
import useDialog from "hooks/useDialog";
import { EditDialog } from "components/dialogs/AlertDialog";
import TextInput from "components/inputs/TextInput";
import ExternalLink from "components/nav/ExternalLink";
import IconButton from "components/buttons/IconButton";
import IconAndTextButton from "components/buttons/IconAndTextButton";

// const StyledComponent = styled.div`
// 	.linksDiv {
// 		display: grid;
// 		grid-template-columns: 2fr 2fr 1fr 1fr;
// 		grid-column-gap: 15px;

// 		div {
// 			border-bottom: 1px solid var(--lines-color);
// 			display: flex;
// 		}
// 	}

// 	.buttons {
// 		display: flex;
// 		justify-content: flex-end;
// 	}
// `;

const StyledComponent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0.5rem 0;
	align-items: center;
	font-size: small;
	border-bottom: 1px solid var(--lines-color);

	.iconAndName, .options, .link, .date {
		display: flex;
		align-items: center;
	}

	.options {
		flex-shrink: 0;
		min-width: 34%;
		padding-left: 0.5rem;
		justify-content: flex-end;
	}

	.iconAndName {
		min-width: 33%;
	}

	.name {
		color: var(--headline-dark);
		font-weight: bold;
		margin-left: 0.5rem;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.link {
		flex-shrink: 0;
		min-width: 33%;
		padding-left: 0.5rem;
	}
`;

const ProjectLinkList = ({ links, changeLink, deleteLink }) => {
	const { presentDialog } = useDialog();

	const editLink = useCallback((linkIndex) => {
		const editedLink = { ...links?.[linkIndex] };
		const changeLinkVal = ({ name, value }) => {
			editedLink[name] = value;
		};

		presentDialog(
			<EditDialog title="Edit Link" okAction={() => changeLink(linkIndex, editedLink)}>
				<div className="editLinkDialog">
					Name:
					<TextInput
						value={links?.[linkIndex]?.name}
						onChange={changeLinkVal}
						name="name"
					/>
					URl:
					<TextInput
						value={links?.[linkIndex]?.href}
						onChange={changeLinkVal}
						name="href"
					/>
				</div>
			</EditDialog>
		);
	}, [changeLink, links, presentDialog]);

	const linkList = links?.filter((f) => !!f).map((link, index) => (
		<StyledComponent key={link.href}>
			<div className="iconAndName">
				<div className="name">{link.name}</div>
			</div>
			<div className="link"><ExternalLink to={link.href}>{link.href}</ExternalLink></div>
			<div className="options">
				<IconButton
					label="Edit Project"
					onClick={() => editLink(index)}
					icon="ant-design:edit-outlined"
				/>
				<IconButton
					onClick={() => deleteLink(index)}
					label="Delete Project"
					icon="ant-design:delete-outlined"
				/>
			</div>
		</StyledComponent>
	)) ?? [];

	// if (linkList.length > 0) {
	// 	linkList.unshift(
	// 		<Fragment key="header">
	// 			<div><strong>Name</strong></div>
	// 			<div><strong>Destination</strong></div>
	// 			<div />
	// 			<div />
	// 		</Fragment>
	// 	);
	// }

	return (
		<>
			<p>
				{ linkList ?? "There are no links for this project" }
			</p>
			<p>
				<IconAndTextButton
					type="button"
					text="Add Link"
					onClick={() => editLink()}
					icon="ant-design:plus-square-outlined"
				/>
			</p>
		</>
	);
};

export default ProjectLinkList;
