import { Icon } from "@iconify/react";
import React from "react";

const IconAndTextButton = ({
	onClick,
	btnRef,
	style,
	tabIndex,
	icon,
	size,
	text
}) => (
	<button
		type="button"
		className="iconButton"
		onClick={onClick}
		ref={btnRef}
		style={{ float: "right", ...style }}
		tabIndex={tabIndex}
	>
		<Icon icon={icon} width={size ?? "15"} height={size ?? "15"} />
		&nbsp;
		{text}
	</button>
);

export default IconAndTextButton;
